<template>
  <div class="lg:container showroom-area">
    <div class="grid grid-cols-1 md:grid-cols-2 items-center">
      <div class="md:order-1">
        <Banner77
          alt="Our Showrooms"
          webp
          width="400"
          height="300"
          path="/images/Homepage/Showroom/"
          xs="XS_Showroom_4x4_x{ratio}.jpg"
          s="S_Showroom_4x4_x{ratio}.jpg"
          m="M_Showroom_4x4_x{ratio}.jpg"
          l="XL-L_Showroom_4x4_x{ratio}.jpg"
        />
      </div>
      <div class="HomepageShowRoomTextArea text-center py-3">
        <h2 class="heading-3 mb-3 lg:mb-5">
          {{ $t('visit-our-showroom') }}
        </h2>
        <p class="body-14-aa mb-3 px-5 xl:px-36 lg:mx-2">
          {{ $t('homepage-visit-our-showrooms-text') }}
        </p>
        <div class="_d-sm-only-none">
          <button type="button" class="btn-link-variant mr-1.5 lg:mr-5" @click="openBookAppointment()">
            {{ $t('book-an-appointment') }}
          </button>
          <NuxtLink class="btn-link-variant" :href="localePath('/showrooms')">
            {{ $t('discover-our-showrooms') }}
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import Banner77 from '~/components/ui/Banner77.vue'
import Popup from '~/components/ui/Popup.vue'

const localePath = useLocalePath()
const { eventBus, EVENTS } = useEventBus()

const openBookAppointment = (showroom) => {
  eventBus.emit(EVENTS.OPEN_BOOK_APPOINTMENTS)
}
</script>

<style scoped lang="scss"></style>
